'use strict'

angular
  .module 'mundoAdmin.units'
  # .config ($stateProvider) ->
  #   $stateProvider
  #     .state 'units',
  #       parent: 'admin'
  #       url: '/units'
  #       views:
  #         '@admin':
  #           templateUrl: 'mundo-admin/units/views/units.tpl.html'
  #           controller: 'TabsCtrl'
  #           controllerAs: 'units'
  #       deepStateRedirect: { default: { state: 'units.overview' } },

  #     .state 'units.overview',
  #       url: '/overview'
  #       data:
  #         'selectedTab': 0
  #       views:
  #         'units@units':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (UnitsManager) ->
  #               UnitsManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.units.units'
  #             pageDescription: ->
  #               'app.admin.descriptions.units.units'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.units.deleteUnit'
  #             deleteBoxBody: ->
  #               'app.admin.actions.units.deleteUnit'
  #             AddBoxTitle: ->
  #               'app.admin.actions.units.addUnit'
  #             editBoxTitle: ->
  #               'app.admin.actions.units.editUnit'
  #             loadFilterParams: ->
  #               {}
  #     .state 'units.groups',
  #       url: '/groups'
  #       data:
  #         'selectedTab': 1
  #       views:
  #         'groups@units':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (UnitGroupsManager) ->
  #               UnitGroupsManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.units.unitGroups'
  #             pageDescription: ->
  #               'app.admin.descriptions.units.unitGroups'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.units.deleteUnitGroup'
  #             deleteBoxBody: ->
  #               'app.admin.actions.units.deleteUnitGroup'
  #             AddBoxTitle: ->
  #               'app.admin.actions.units.addUnitGroup'
  #             editBoxTitle: ->
  #               'app.admin.actions.units.editUnitGroup'
  #             loadFilterParams: ->
  #               {}
